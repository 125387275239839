import { commonConstants } from '../_constants';

let sidebar_size = (localStorage.getItem('sidebar') === 'true' || localStorage.getItem('sidebar') === null);

const initialState = {
  detail: {},
  sidebar: false,
  sidebar_size: sidebar_size,
  loading: false,
  error: null
};

export function config(state = initialState, action) {
  switch (action.type) {
    case commonConstants.GET_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case commonConstants.GET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.detail
      };
    case commonConstants.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.status
      };
    case commonConstants.RESIZE_SIDEBAR:
    return {
      ...state,
      sidebar_size: action.status
    };
    case commonConstants.GET_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state
  }
}