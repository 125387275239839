import { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class Logout extends Component {

  componentWillMount() {
    this.props.logout();
    this.props.history.push('/path')
  }

  render() {
    return null
  }
}


function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    logout: userActions.logout
};

export default connect(mapState, actionCreators)(Logout);
