import { userConstants } from '../_constants';
import jwt from 'jwt-decode' // import dependency

let valid= false;
let user = JSON.parse(localStorage.getItem('user'));
const token = user && user["token"];
if (token) {
  if (jwt(token).exp < Date.now() / 1000) {
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
  } else {
    valid = true;
  }
}

const initialState = user && valid ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        //user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
